import React from "react";
import "./style.css";

function Navbar(props) {
    return (
        <div className="navigation-bar">
            <ul>
                <li><a className="navbar-brand" href="/"><img src="./../../images/Usommelier-logo2.svg" height="40" alt=""></img></a></li>
                <li><a href="/lessons">Lessons</a></li>
                {/* <li><a href="/practice">Practice</a></li>
                <li><a className="settings-btn" href="/settings">Settings</a></li> */}
            </ul>
        </div>
    );
}

export default Navbar;
