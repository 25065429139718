import React from "react";
//import UpdateForm from "../components/updateform/index";
import "./style.css";

function Contactform(props) {
    return (
        <div>
            <form className="contact-form was-validated" action="https://formspree.io/arthur@arthurdoelp.com" method="POST">
                <h3>Send Feedback</h3>
                <h4>Any feedback is greatly appreciated</h4>
                <div className="contact-form-questions">
                    <div class="form-group">
                        <label for="input-name">Name</label>
                        <input type="text" class="form-control" id="input-name" placeholder="Enter name" name="name" required></input>
                        <div class="invalid-feedback">
                            Please add your name
                                    </div>
                        <div class="valid-feedback">
                            Thanks!
                                    </div>
                    </div>
                    <div class="form-group">
                        <label for="input-email">Email address</label>
                        <input type="email" class="form-control" id="input-email" aria-describedby="emailHelp" placeholder="Enter email" name="email" required></input>
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                        <div class="invalid-feedback">
                            Please add your email
                                    </div>
                        <div class="valid-feedback">
                            Thanks!
                                    </div>
                    </div>
                    <div class="form-group">
                        <label for="message-input">Message</label>
                        <textarea class="form-control" id="message-input" rows="3" placeholder="Enter message or any feedback" name="message" required></textarea>
                        <div class="invalid-feedback">
                            Please add your message
                                    </div>
                        <div class="valid-feedback">
                            Thanks!
                                    </div>
                    </div>
                    <button type="submit" class="contact-submit-btn" value="Send">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Contactform;