import React, { Component } from "react";
//import UpdateForm from "../components/updateform/index";
import Units from "../../components/Units/index";
import unitdata from "../../units.json";
import "./style.css";
import Footer from "../../components/Footer/index";
import Navbar from "../../components/Navbar/index";
import Contactform from "../../components/Contactform/index";

class Lessons extends Component {
    state = {
        unitdata
    }

    clickedUnit = title => {
        console.log(title);
        if (title) {
            localStorage.setItem("unit", title);
        }
    }

    render() {
        return (
            <div>
                <Navbar></Navbar>
                <div className="container">
                    <div className="row">
                    <h1>Units</h1>
                        <div className="scroll-container">
                            <div className="col-sm-12 col-md-12 col-lg-12">

                                {this.state.unitdata.map(unit => (
                                    <div className="scroll-units">
                                        <Units
                                            clickedUnit={this.clickedUnit}
                                            id={unit.id}
                                            title={unit.title}
                                            image={unit.image}
                                            locked={unit.locked}
                                        />
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="ad-container">
                                <img src="https://picsum.photos/300/300"></img>
                                <p>Ad goes here</p>
                            </div>
                            <div className="contact-form-section">
                                <p>Any ideas you have we welcome with open arms. </p>
                                <Contactform></Contactform>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Lessons;
